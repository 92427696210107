export function getCurrentTime(sep = ' / ', t?: string) {
  const d = t ? new Date(t) : new Date()
  const h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()
  const m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
  const s = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()
  const tempMonth = d.getMonth() + 1
  const month = tempMonth < 10 ? '0' + tempMonth : tempMonth
  const time = `${h} : ${m} : ${s}`
  const date = `${d.getFullYear()} 年 ${d.getMonth() + 1} 月 ${d.getDate()}日 ${time}`
  const weekArr = ['日', '一', '二', '三', '四', '五', '六']
  const week = '星期' + weekArr[d.getDay()]
  const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
  return {
    date: `${d.getFullYear()}${sep}${month}${sep}${day}`,
    time,
    week
  }
}