<script setup lang="ts">
import { useRoute } from "#imports";
import type { MenuItem } from "./types";
const route = useRoute();

const props = defineProps<{
  menus: MenuItem[];
}>();
</script>
<template>
<div class="flex items-end justify-center relative h-full">
  <div v-for="(item, index) in menus" :key="index"
    class="w-[13.125rem] h-[2.8125rem] flex items-center justify-center bg-cover -ml-5 cursor-pointer" :style="[
    `background-image: url(${route.fullPath === item.url ? '/menu-active' : '/menu'
    }.svg);`,
  ]" @click="$router.push(item.url)">

    <img class="w-5 h-5" :src="item.icon" alt="" />
    <p class="text-base ml-2 font-bold" style="color: #d9f1fd">
      {{ item.label }}
    </p>
  </div>
</div>
</template>
