<script setup lang="ts">
import { getCurrentTime } from "../utils/time";
import { onBeforeMount, ref } from "vue";
import Menu from "./menu.vue";
import type { MenuItem } from "./types";
import { useRuntimeConfig, useRouter, useRoute } from "#imports";
const runtimeConfig = useRuntimeConfig();
const router = useRouter();
const route = useRoute();
const time = ref();
const projectId = route.params.project_id
const defaultMenus = ref([]);
// const projectInfo = ref()
onBeforeMount(() => {
  const timeVal = getCurrentTime();
  time.value = timeVal;
  setInterval(() => {
    const timeVal = getCurrentTime();
    time.value = timeVal;
  }, 1000);
});

const logo = runtimeConfig.public.site.logo as string;

const props = defineProps<{
  menus?: MenuItem[]
  projectInfo?: any
  isWeather?: boolean
  backable?: boolean
}>();
const config = useRuntimeConfig()
const back = () => {
  window.location.href = `/bim/${projectId}/collection/model/list`
};
</script>
<template><!-- 大屏特有的header -->
<div class="w-full flex div header h-[4.375rem] fixed z-20" v-if="projectInfo">
  <div class="title flex justify-between items-center px-6 w-full h-full absolute"
    style="top: 50%; transform: translateY(-50%)">
    <div class="flex justify-center items-center">
      <slot name="logo">
        <img :src="logo" class="h-12 w-12" />
      </slot>
      <div class="ml-6 flex flex-col my-2">
        <p class="text-[1.375rem] text-white font-bold tracking-wider" style="color: #e9f7ff">
          {{ projectInfo.name }}
        </p>
        <p class="text-[0.625rem] text-white font-bold opacity-80 tracking-wide"
          style="color: rgba(165, 216, 252, 0.4)">
          {{ projectInfo.shortname }}
        </p>
      </div>
    </div>
    <div class="self-end h-full pr-24" v-if="menus && menus.length">
      <Menu :menus="menus"></Menu>
    </div>
    <div class="flex items-center" style="color: #d9f1fdfe">
      <div v-if="isWeather" class="flex justify-center items-center mr-10">
        <!-- <img class="w-7 h-7 mr-3 text-base" src="/weather.png" alt="" /> -->
        <t-icon class="mr-3 cursor-pointer" size="2em" name="cloudy-sunny"></t-icon>
        <div>
          <p class="text-base">20~28℃</p>
          <p class="text-sm">晴转多云 优</p>
        </div>
      </div>
      <div v-if="time" class="flex justify-center items-center">
        <!-- <img class="w-7 h-7 mr-3" src="/clock.svg" alt="" /> -->
        <t-icon class="mr-3 cursor-pointer" size="2em" name="time"></t-icon>
        <div class="flex flex-col items-center">
          <p class="text-base">{{ time.time }}</p>
          <p class="text-sm">{{ time.date }} <span class="text-xs">{{ time.week }}</span></p>
        </div>
      </div>
      <slot name="back">
        <t-icon class="ml-6 cursor-pointer" size="2em" name="houses" @click="back()"></t-icon>
      </slot>
    </div>
  </div>
</div>
</template>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(90deg,
      rgba(36, 81, 92, 0.8) 0%,
      rgba(16, 22, 31, 0.996) 34%,
      rgba(16, 22, 31, 0.902) 100%);
}
</style>
